<template>
  <transition name="slide-preview-basket">
    <div
      v-if="show_preview_basket && !is_mobile_device"
      class="preview-basket"
      @mouseover="update_mouse_over_basket(true)"
      @mouseleave="hide_cart"
    >
      <div
        class="preview-basket__items-wrapper"
        :class="{ 'preview-basket__items-wrapper--marketplace': is_marketplace_environment }"
      >
        <p
          v-if="basket_items.length === 0"
          class="preview-basket__message"
        >{{ translations.empty_basket || $translate("basket.empty_basket") }}</p>
        <Basket_item
          v-else
          v-for="(item, index) in basket_items"
          :key="`basket-item-${index}`"
          :product_data="item"
          :small_size="true"
          :selected_language="selected_language"
        />
        <router-link to="/basket" aria-label="Go to basket" itemprop="basket">
          <div
            v-if="basket_items.length > 0"
            class="preview-basket__button button button--green"
          >
            {{ translations.go_to_basket || $translate("basket.go_to_basket") }}
          </div>
        </router-link>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import Basket_item from "./basket_item"
import { CLIENT_STORE } from "../../../../Client/constants/other"
import { SHARED_STORE } from "../../../constants/other"
import { TOGGLE_PREVIEW_BASKET, UPDATE_MOUSE_OVER_BASKET } from "../../../stores/Shared/constants"

export default {
  components:{
    Basket_item
  },
  data() {
    return {
      height_by_which_shorten_basket: 0,
      initial_load: true,
      mouse_over: false
    }
  },
  computed: {
    ...mapState(SHARED_STORE, [
      "is_mobile_device",
      "first_load",
      "show_preview_basket",
      "basket_items"
    ]),
    ...mapState(CLIENT_STORE, [
      "translations",
      "selected_language"
    ]),
    ...mapState([
      "is_marketplace_environment"
    ])
  },
  watch: {
    first_load() {
      if(this.basket_items.length === 0)
        this.initial_load = false
    },
    $route() {
      this.toggle_preview_cart([false])
    }
  },
  mounted() {
      this.toggle_preview_cart([false])
  },
  methods: {
    ...mapMutations(SHARED_STORE, {
      toggle_preview_cart: TOGGLE_PREVIEW_BASKET,
      update_mouse_over_basket: UPDATE_MOUSE_OVER_BASKET
    }),
    /*
     * 
     */
    hide_cart() {
      this.update_mouse_over_basket(false)
      this.toggle_preview_cart([false])
    },
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_global-constants" as *;
  @use "../../../../../styles/_marketplace-constants" as *;

  .preview-basket {
    position: absolute;
    top: 100%;
    right: 0;
    width: 420px;
    z-index: 10;
    padding: $spacing--medium $spacing--small 0 0;

    @media (max-width: $tablet) {
      display: none;
    }

    &__items-wrapper {
      padding: $spacing--tiny 5px 0;
      max-height: calc(100vh - #{$menu-height} - 2 * #{$spacing--medium});
      overflow-y: auto;
      border-radius: 6px;
      box-shadow: 0 0 20px -4px $shadow-color;
      background: var(--body_color);

      &--marketplace {
        max-height: calc(100vh - #{$menu-height--expanded} - 2 * #{$spacing--medium});
      }
    }

    &__message {
      padding: 5px;
      color: var(--font_heavy);
      text-align: center;
    }

    .cart-item__wrapper:last-child {
      border-bottom: none;
    }

    &__button {
      position: sticky;
      bottom: $spacing--small;
      width: 80%;
      margin: 0 auto $spacing--small;
    }
  }

  .slide-preview-basket-enter-active, .slide-preview-basket-leave-active {
    transition: .5s;
  }
  .slide-preview-basket-enter, .slide-preview-basket-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateX(30px);
  }
</style>
